import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class ThankYou extends React.Component {
  render() {
    const siteTitle = 'About J & J Gates Service and Design'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Thank You</h1>
            <hr />
          </div>
        </div>
        <div className="container custom-container pt-5 d-padding-b text-justify">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h5 className="mb-5">
                We have received your message and will get back to you shortly.
              </h5>
              <Link className="btn-global" href="/">
                Return Home
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ThankYou

export const ThankYouPageQuery = graphql`
  query ThankYouPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
